import React from 'react';
import { Mail, BrandLinkedin, BrandGithub, Movie, Briefcase } from 'tabler-icons-react';

import BrandPython from '../icons/tabler-brand-python.svg';
import BrandReact from '../icons/tabler-brand-react.svg';
import BrandHtml5 from '../icons/brand-html5.svg';
import BrandTailwind from '../icons/brand-tailwind.svg';
import BrandAWS from '../icons/brand-aws.svg';
import PostgresqlLogo from '../icons/postgresql-logo.svg';
import TablerSQL from '../icons/tabler-icons-sql.svg';
import TablerSwift from '../icons/tabler-swift.svg';
import FlaskLogo from '../icons/flask-logo.svg';
import BrandGit from '../icons/brand-git.svg';
import JestLogo from '../icons/jest-logo.svg';
import BrandJavaScript from '../icons/brand-javascript.svg';

const SocialLink = ({ href, icon: Icon, label }) => (
  <a 
    href={href} 
    target="_blank" 
    rel="noopener noreferrer" 
    className="text-white hover:text-gray-200 transform hover:scale-110 transition-all duration-300"
    aria-label={label}
  >
    <Icon size={32} className="sm:w-8 sm:h-8" />
  </a>
);

const SkillCard = ({ name, icon }) => (
  <div className="bg-white p-2 rounded-lg flex flex-col items-center justify-between h-full transform hover:scale-105 hover:shadow-xl transition-all duration-300">
    <div className="flex-grow flex items-center justify-center w-full p-2">
      <img
        src={icon}
        alt={name}
        className="w-full h-auto max-w-[85%] object-contain"
      />
    </div>
    <span className="text-black text-center font-bold text-sm mt-2">
      {name}
    </span>
  </div>
);

const Landing = () => {
  const skills = [
    { name: 'Python', icon: BrandPython },
    { name: 'Flask', icon: FlaskLogo },
    { name: 'SQL', icon: TablerSQL },
    { name: 'PostgreSQL', icon: PostgresqlLogo },
    { name: 'AWS', icon: BrandAWS },
    { name: 'Git', icon: BrandGit },
    { name: 'Jest', icon: JestLogo },
    { name: 'JavaScript', icon: BrandJavaScript },
    { name: 'React', icon: BrandReact },
    { name: 'Tailwind', icon: BrandTailwind },
    { name: 'HTML+CSS', icon: BrandHtml5 },
    { name: 'Swift', icon: TablerSwift },
  ];

  const socialLinks = [
    { href: "https://www.linkedin.com/in/ihnen/", icon: BrandLinkedin, label: "LinkedIn" },
    { href: "mailto:maxihnen@gmail.com", icon: Mail, label: "Email" },
    { href: "https://github.com/maxdenuevo", icon: BrandGithub, label: "GitHub" },
    { href: "https://letterboxd.com/cinenauta/", icon: Movie, label: "Letterboxd" },
    { href: "https://www.getonbrd.com/p/max-ihnen", icon: Briefcase, label: "GetOnBrd" }
  ];

  return (
    <div className="font-sans bg-[#285DEF]">
      <section className="bg-[#262333] p-4 sm:p-8 md:p-10 lg:p-16 xl:p-24">
        <div className="container mx-auto px-4 xl:px-8 2xl:px-16 flex flex-wrap md:flex-nowrap items-center">
          <div className="w-full md:w-1/3 mb-8 md:mb-0 flex flex-col items-center">
            <div className="relative mb-8 transform rotate-3 hover:rotate-6 transition-transform duration-300">
            <div 
  className="w-80 h-80 sm:w-80 sm:h-80 lg:w-80 lg:h-80 relative"
>
  <svg width="0" height="0">
    <defs>
      <clipPath id="blob" clipPathUnits="objectBoundingBox">
        <path d="M0.5,0.05 C0.75,0.05 0.95,0.25 0.95,0.5 C0.95,0.75 0.75,0.95 0.5,0.95 C0.25,0.95 0.05,0.75 0.05,0.5 C0.05,0.25 0.25,0.05 0.5,0.05 Z" />
      </clipPath>
    </defs>
  </svg>
  <div 
    className="w-full h-full overflow-hidden"
    style={{
      clipPath: 'url(#blob)',
      backgroundColor: '#285DEF'
    }}
  >
    <img 
      src="/images/profile.jpg" 
      alt="Max Ihnen" 
      className="w-full h-full object-cover transform scale-95 hover:scale-100 transition-all duration-300"
    />
  </div>
</div>
            </div>            <div className="bg-gradient-to-r from-purple-500 via-purple-600 to-purple-700 rounded-full px-6 py-4 flex space-x-6 transform hover:scale-105 transition-all duration-300">
              {socialLinks.map((link, index) => (
                <SocialLink key={index} {...link} />
              ))}
            </div>
          </div>

          {/* JSON Introduction */}
          <div className="w-full md:w-2/3 flex items-center justify-center md:justify-end">
            <pre className="text-left text-lg sm:text-xl md:text-2xl bg-gray-900 p-6 sm:p-8 rounded-lg w-full md:max-w-lg transform hover:scale-105 transition-all duration-300">
              <code>
                <span className="text-gray-400">{'{'}</span>
                <br />
                <span className="text-emerald-400">"name"</span>
                <span className="text-gray-400">:</span>
                <span className="text-orange-400"> "max"</span>
                <span className="text-gray-400">,</span>
                <br />
                <span className="text-emerald-400">"occupation"</span>
                <span className="text-gray-400">:</span>
                <span className="text-orange-400"> "full stack dev"</span>
                <span className="text-gray-400">,</span>
                <br />
                <span className="text-emerald-400">"likes"</span>
                <span className="text-gray-400">:</span>
                <span className="text-orange-400"> ["movies", "tacos"]</span>
                <br />
                <span className="text-gray-400">{'}'}</span>
              </code>
            </pre>
          </div>
        </div>
      </section>

      {/* Skills Section */}
      <section className="bg-[#285DEF] p-4 sm:p-8 md:p-10 lg:p-16 xl:p-24">
        <div className="container mx-auto px-4 xl:px-8 2xl:px-16">
          <h2 className="text-black text-5xl sm:text-6xl font-black mb-12">Skillset</h2>
          
          {/* Skills Grid */}
          <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-4 sm:gap-6 lg:gap-8">
            {skills.map((skill, index) => (
              <SkillCard key={index} {...skill} />
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Landing;