import React, { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import DownloadableCV from './DownloadableCV';

const CV = () => {
  const [markdown, setMarkdown] = useState('');

  useEffect(() => {
    fetch('/cv.md')
      .then(response => response.text())
      .then(text => setMarkdown(text))
      .catch(error => console.error('Error loading CV:', error));
  }, []);

  useEffect(() => {
    fetch('/cv.md')
      .then(response => response.text())
      .then(text => {
        console.log('Loaded markdown:', text.substring(0, 500)); 
        setMarkdown(text);
      })
      .catch(error => console.error('Error loading CV:', error));
  }, []);

  return (
    <div className="bg-blue-50 min-h-screen pt-20 pb-12">
      <div className="container mx-auto px-4 py-8 max-w-4xl bg-white shadow-lg rounded-lg">
        {/* Web view of the CV */}
        <div className="prose max-w-none">
          <ReactMarkdown
            components={{
              h1: ({ node, ...props }) => (
                <>
                  <h1 className="text-4xl font-bold mb-4 text-gray-800" {...props} />
                  <hr className="border-t border-blue-200 my-6" />
                </>
              ),
              h2: ({ node, ...props }) => (
                <>
                  <h2 className="text-2xl font-semibold mt-8 mb-4 text-gray-700" {...props} />
                  <hr className="border-t border-blue-100 my-4" />
                </>
              ),
              h3: ({ node, ...props }) => (
                <h3 className="text-xl font-semibold mt-6 mb-3 text-gray-600" {...props} />
              ),
              p: ({ node, ...props }) => (
                <p className="mb-4 text-gray-700" {...props} />
              ),
              ul: ({ node, ...props }) => (
                <ul className="list-disc pl-5 mb-4 text-gray-700" {...props} />
              ),
              li: ({ node, ...props }) => (
                <li className="mb-1" {...props} />
              ),
              a: ({ node, ...props }) => (
                <a className="text-blue-600 hover:underline" {...props} />
              ),
            }}
          >
            {markdown}
          </ReactMarkdown>
        </div>
        

        {/* Downloadable PDF version */}
        {markdown && <DownloadableCV markdown={markdown} />}
      </div>
    </div>
  );
};

export default CV;