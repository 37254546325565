import React from 'react';
import { Document, Page, Text, View, StyleSheet, Link, PDFDownloadLink } from '@react-pdf/renderer';
import { unified } from 'unified';
import remarkParse from 'remark-parse';
import remarkGfm from 'remark-gfm';
import { visit } from 'unist-util-visit';

// Define fonts, colors, and spacing
const colors = {
  gray: {
    700: '#374151',
    800: '#1F2937',
    900: '#111827',
  },
  blue: {
    100: '#E6E6FF',
    600: '#2563EB',
  },
};

const styles = StyleSheet.create({
  page: {
    padding: 35,
    fontFamily: 'Helvetica',
    fontSize: 11,
    lineHeight: 1.4,
  },
  header: {
    marginBottom: 12,
  },
  h1: {
    fontSize: 24,
    fontFamily: 'Helvetica-Bold',
    color: colors.gray[900],
    marginBottom: 6,
  },
  h2: {
    fontSize: 16,
    fontFamily: 'Helvetica-Bold',
    color: colors.gray[800],
    marginTop: 12,
    marginBottom: 4,
  },
  h3: {
    fontSize: 13,
    fontFamily: 'Helvetica-Bold',
    color: colors.gray[700],
    marginTop: 8,
    marginBottom: 3,
  },
  text: {
    fontSize: 11,
    marginBottom: 2,
    color: colors.gray[700],
  },
  list: {
    marginLeft: 12,
    marginBottom: 4,
  },
  listItem: {
    flexDirection: 'row',
    marginBottom: 2,
  },
  bullet: {
    width: 8,
    fontSize: 11,
  },
  listContent: {
    flex: 1,
    paddingRight: 8,
  },
  link: {
    color: colors.blue[600],
    textDecoration: 'underline',
  },
  divider: {
    borderBottomWidth: 1,
    borderBottomColor: colors.blue[100],
    marginVertical: 4,
  },
  contactInfo: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginBottom: 8,
    gap: 8,
  },
});

// Helper to parse inline links and text
const renderTextContent = (node) => {
  if (node.type === 'link') {
    return (
      <Link src={node.url} style={styles.link}>
        {node.children[0].value}
      </Link>
    );
  }
  return node.value;
};

// Clean and process text content
const cleanText = (text = '') => {
  return text.replace(/\s+/g, ' ').trim();
};

// Main document component
const CVDocument = ({ content }) => {
  const ast = unified()
    .use(remarkParse)
    .use(remarkGfm)
    .parse(content);

  const renderNode = (node, index) => {
    switch (node.type) {
      case 'heading': {
        const HeadingStyle = styles[`h${node.depth}`];
        return (
          <View key={index} style={styles.header}>
            <Text style={HeadingStyle}>
              {cleanText(node.children[0]?.value || '')}
            </Text>
            {node.depth <= 2 && <View style={styles.divider} />}
          </View>
        );
      }

      case 'paragraph': {
        return (
          <View key={index} style={{ marginBottom: 3 }}>
            <Text style={styles.text}>
              {node.children.map((child, i) => (
                <React.Fragment key={i}>
                  {renderTextContent(child)}
                </React.Fragment>
              ))}
            </Text>
          </View>
        );
      }

      case 'list': {
        return (
          <View key={index} style={styles.list}>
            {node.children.map((item, itemIndex) => {
              const listContent = item.children[0].children
                .map(child => renderTextContent(child))
                .join('');

              return (
                <View key={itemIndex} style={styles.listItem}>
                  <Text style={styles.bullet}>•</Text>
                  <View style={styles.listContent}>
                    <Text style={styles.text}>{cleanText(listContent)}</Text>
                  </View>
                </View>
              );
            })}
          </View>
        );
      }

      default:
        return null;
    }
  };

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        {ast.children.map((node, index) => renderNode(node, index))}
      </Page>
    </Document>
  );
};

const DownloadableCV = ({ markdown }) => {
  // Update education details
  const enhancedMarkdown = markdown.replace(
    ', 4Geeks Academy (2024)',
    'Full Stack Developer Certificate, 4Geeks Academy (2024)\n• 400+ hours of intensive programming training\n• Focus on Python backend development\n• Advanced web development practices\n• Project-based learning with real-world applications\n• Key technologies: Python, Flask, React, SQL\n• Completed 4 major projects including freelance platform'
  );

  if (!enhancedMarkdown) {
    console.error('No markdown content provided to DownloadableCV');
    return null;
  }

  return (
    <div className="fixed bottom-6 right-6">
      <PDFDownloadLink
        document={<CVDocument content={enhancedMarkdown} />}
        fileName="Max_Ihnen_CV.pdf"
      >
        {({ loading, error }) => (
          <button
            className={`
              bg-blue-600 hover:bg-blue-700 
              text-white font-bold py-3 px-6 
              rounded-full shadow-lg 
              flex items-center space-x-3
              transition-all duration-300
              ${loading ? 'opacity-50 cursor-wait' : 'hover:scale-105'}
              ${error ? 'bg-red-600 hover:bg-red-700' : ''}
            `}
            disabled={loading}
          >
            <svg 
              className="w-6 h-6" 
              fill="none" 
              stroke="currentColor" 
              viewBox="0 0 24 24"
            >
              <path 
                strokeLinecap="round" 
                strokeLinejoin="round" 
                strokeWidth={2} 
                d="M12 10v6m0 0l-3-3m3 3l3-3m2 8H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" 
              />
            </svg>
            <span className="text-lg">
              {error ? 'Error - Try Again' : 
               loading ? 'Preparing PDF...' : 'Download CV'}
            </span>
          </button>
        )}
      </PDFDownloadLink>
    </div>
  );
};

export default DownloadableCV;