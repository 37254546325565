import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Link, useLocation } from 'react-router-dom';
import { Mail, BrandLinkedin, BrandGithub, Movie, Menu2, X, Briefcase } from 'tabler-icons-react';
import Landing from './components/Landing';
import Portfolio from './components/Portfolio';
import CV from './components/CV';

const NavLink = ({ to, children, onClick }) => {
  const location = useLocation();
  const isActive = location.pathname === to;
  
  return (
    <Link
      to={to}
      onClick={onClick}
      className={`text-black font-bold text-lg sm:text-xl transition-all duration-300 relative group ${
        isActive ? 'opacity-100' : 'opacity-80 hover:opacity-100'
      }`}
    >
      {children}
      <span className={`absolute left-0 bottom-0 w-full h-0.5 bg-black transform origin-left transition-transform duration-300 ${
        isActive ? 'scale-x-100' : 'scale-x-0 group-hover:scale-x-100'
      }`} />
    </Link>
  );
};

const SocialLink = ({ href, icon: Icon, label }) => (
  <a
    href={href}
    target="_blank"
    rel="noopener noreferrer"
    className="text-white hover:text-gray-200 transform hover:scale-110 transition-all duration-300"
    aria-label={label}
  >
    <Icon size={24} />
  </a>
);

function App() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    if (isMenuOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isMenuOpen]);

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);
  
  const closeMenu = () => {
    setIsMenuOpen(false);
    document.body.style.overflow = 'unset';
  };

  return (
    <Router>
      <div className="App flex flex-col min-h-screen">
        <nav className="bg-[#285DEF] p-4 sm:p-5 md:p-5 lg:p-8 xl:p-10 2xl:p-12 fixed w-full top-0 z-50">
          <div className="container mx-auto flex justify-between items-center px-4 xl:px-8 2xl:px-16">
            <Link 
              to="/" 
              className="text-black text-4xl sm:text-5xl md:text-6xl lg:text-7xl xl:text-8xl 2xl:text-9xl font-black transition-transform duration-300 hover:scale-105"
              onClick={closeMenu}
            >
              Max Ihnen
            </Link>
            
            {/* Desktop Navigation */}
            <div className="hidden md:flex space-x-6 lg:space-x-8 xl:space-x-10">
              <NavLink to="/">Skillset</NavLink>
              {/* <NavLink to="/portfolio">Portfolio</NavLink> */}
              <NavLink to="/cv">CV</NavLink>
            </div>

            {/* Mobile Menu Button */}
            <button
              onClick={toggleMenu}
              className="md:hidden text-black hover:text-gray-700 transition-colors duration-300"
              aria-label="Toggle menu"
            >
              {isMenuOpen ? <X size={32} /> : <Menu2 size={32} />}
            </button>
          </div>

          <div
            className={`md:hidden fixed top-[calc(100% + 1px)] left-0 w-full bg-[#285DEF] transform transition-all duration-300 ease-in-out ${
              isMenuOpen ? 'translate-y-0 opacity-100' : '-translate-y-full opacity-0'
            }`}
            style={{
              height: isMenuOpen ? 'calc(100vh - 100%)' : '0',
              pointerEvents: isMenuOpen ? 'auto' : 'none'
            }}
          >
            <div className="container mx-auto px-4 py-4 flex flex-col space-y-4">
              <NavLink to="/" onClick={closeMenu}>Skillset</NavLink>
              {/* <NavLink to="/portfolio" onClick={closeMenu}>Portfolio</NavLink> */}
              <NavLink to="/cv" onClick={closeMenu}>CV</NavLink>
            </div>
          </div>
        </nav>

        {isMenuOpen && (
          <div 
            className="md:hidden fixed inset-0 bg-black bg-opacity-50 z-40"
            onClick={closeMenu}
          />
        )}

        <main className="flex-grow bg-[#285DEF] mt-16 sm:mt-20 md:mt-24 lg:mt-32 xl:mt-40 2xl:mt-48">
          <Routes>
            <Route path="/" element={<Landing />} />
            <Route path="/portfolio" element={<Portfolio />} />
            <Route path="/cv" element={<CV />} />
          </Routes>
        </main>

        <footer className="bg-[#262333] text-white p-4">
          <div className="container mx-auto flex flex-col items-center">
            <div className="flex justify-center space-x-6 mb-2">
              <SocialLink 
                href="https://www.linkedin.com/in/ihnen/"
                icon={BrandLinkedin}
                label="LinkedIn"
              />
              <SocialLink 
                href="mailto:maxihnen@gmail.com"
                icon={Mail}
                label="Email"
              />
              <SocialLink 
                href="https://github.com/maxdenuevo"
                icon={BrandGithub}
                label="GitHub"
              />
              <SocialLink 
                href="https://letterboxd.com/cinenauta/"
                icon={Movie}
                label="Letterboxd"
              />
              <SocialLink 
                href="https://www.getonbrd.com/p/max-ihnen"
                icon={Briefcase}
                label="GetOnBrd"
              />
            </div>
            <div className="text-sm opacity-80 hover:opacity-100 transition-opacity duration-300">
              © 2025 Max Ihnen
            </div>
          </div>
        </footer>
      </div>
    </Router>
  );
}

export default App;